import api from "~/utils/api/api";
import { NewAnnouncement } from "~/utils/interfaces/Announcement";
import { Locale } from "~/utils/interfaces/Locale";
import { NewNurse } from "~/utils/interfaces/User";

const baseUrl = "nurses";

class NurseService {
  createNurse = async (nurseData: NewNurse) => {
    return api.post(`${baseUrl}/`, nurseData);
  };

  updateNurse = async (id: string, data: any) => {
    return api.patch(`${baseUrl}/${id}/`, data);
  };

  activateNurse = async (id: string, data: any) => {
    return api.patch(`${baseUrl}/${id}/activate/`, data);
  };

  fetchNurseProfile = async (nurseId: string) => {
    return api.get(`${baseUrl}/${nurseId}/`);
  };

  fetchActiveNurses = async (locale?: Locale) => {
    return api.get(`${baseUrl}/active_nurses/`, {
      params: { country: locale },
    });
  };

  fetchInactiveNurses = async (locale?: Locale) => {
    return api.get(`${baseUrl}/inactive_nurses/`, {
      params: { country: locale },
    });
  };

  fetchAppointments = async (nurseId: string, status: string) => {
    return api.get(`dashboard/appointments/?nurse_id=${nurseId}&status=${status}`);
  };

  uploadProfilePicture = async (nurseId: string, formData: any) => {
    return api.post(`${baseUrl}/${nurseId}/upload_photo/`, formData);
  };

  fetchServices = async (nurseId: string, category?: string) => {
    return api.get(`${baseUrl}/lab_services/`, {
      params: { nurse_id: nurseId, category: category },
    });
  };

  fetchServicesCategories = async (nurseId: string) => {
    return api.get(`${baseUrl}/${nurseId}/services_categories/`);
  };

  fetchNurseSpecialties = async () => {
    return api.get("nurse-specialties/");
  };

  fetchNurseAnnouncements = async (currentPage = 1, country_code = "cl") => {
    const params = {
      page: currentPage,
      country_code: country_code,
    };
    return api.get(`notification/announcements`, {
      params: params,
    });
  };

  uploadNurseAnnouncement = async ({ title, message, country_code, is_private, nurses }: NewAnnouncement) => {
    const data = {
      title: title,
      message: message,
      country_code: country_code,
      target: "nurses",
      private: is_private,
      nurses: nurses,
    };
    return api.post(`notification/announcements/`, data);
  };

  deleteNurseAnnouncement = async (announcementId: string) => {
    const data = {
      id: announcementId,
    };
    return api.delete(`notification/announcements/`, { data: data });
  };
}

const nurseService = new NurseService();
export default nurseService;
